import * as moment from "moment-timezone";

const SA_TIMEZONE = "Asia/Riyadh"; // Saudi Arabia Time (UTC+3)

function calculateBusinessHoursPassed(creationDate: string): number {
  let start = moment.utc(creationDate).tz(SA_TIMEZONE); // Convert to SA time
  const now = moment().tz(SA_TIMEZONE); // Current SA time

  let hours = 0;

  while (start.isBefore(now)) {
    if (![5, 6].includes(start.isoWeekday())) {
      // Skip Fridays (5) & Saturdays (6)
      hours++;
    }
    start.add(1, "hour"); // Move one hour forward
  }

  return hours;
}

export function passedHoursColorCode(creationDate: string): string {
  const hoursPassed = calculateBusinessHoursPassed(creationDate);
  if (hoursPassed < 24) {
    return "valid";
  } else if (hoursPassed <= 26) {
    return "warning";
  } else {
    return "critical";
  }
}

export function lawyerPassedHoursColorCode(assignmentDate: string, lawyerAcceptanceDate: string | null): string {
  const dateAssigned = new Date(assignmentDate);
  const dateAccepted = lawyerAcceptanceDate ? new Date(lawyerAcceptanceDate) : null;

  //handle the case when case/inquiry is accepted by lawyer or re-assigned to new lawyer
  // if (!!lawyerAcceptanceDate && dateAccepted && dateAccepted > dateAssigned) {
  if (!!lawyerAcceptanceDate && dateAccepted) {
    // const hoursPassed = calculateBusinessHoursPassed(lawyerAcceptanceDate);
    // if (hoursPassed < 240) {
      // less than 2 weeks (5 working days)
      return "valid";
    // } else {
    //   return "critical";
    // }
  } else {
    return passedHoursColorCode(assignmentDate);
  }
}

export function chooseStartDate(mandatoryDate: string, optionalDate: string | null): string {
  const dateMandatory = new Date(mandatoryDate);
  const dateOptional = optionalDate ? new Date(optionalDate) : null;
  if (optionalDate && dateOptional && dateOptional > dateMandatory) {
    return optionalDate;
  } else {
    return mandatoryDate;
  }
}
