import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import {
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  TableService,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { AssignDialogComponent } from "../assign-dialog/assign-dialog.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { UserRoles } from "src/app/shared/models/interfaces";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";
import { FilterUsersDialogComponent } from "src/app/users/dialogs/filter-users-dialog/filter-users-dialog.component";
import { Router } from "@angular/router";

enum RowActions {
  transferToLawyer = "transferToLawyer",
  transferToReconciliation = "transferToReconciliation",
  transferToSupervisor = "transferToSupervisor",
  caseEnding = "caseEnding",
}

@Component({
  selector: 'app-user-inquiries-list',
  templateUrl: './user-inquiries-list.component.html',
  styleUrls: ['./user-inquiries-list.component.scss']
})
export class UserInquiriesListComponent  extends DataTableComponentBase implements OnInit, OnDestroy {
  moduleName = "userInquiries";
  url = "user-inquiries";
  displayedColumns: TableSchema[] = [
    {
      title: "inquiry-type",
      id: "caseTitle",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "inquiry-code",
      id: "application_no",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "inquiry-status",
      id: "status",
      type: TableTypes.select,
      list: [
        { title: "inquiry-new", value: "pending", fontColor: "#E5B46D" },
        { title: "active", value: "active", fontColor: "#1246AB" },
        { title: "inquiry-closed", value: "closed", fontColor: "#565252" },
        { title: "rejected", value: "rejected", fontColor: '#AB1212' },
        { title: "declined", value: "declined", fontColor: "#0F6E5F" },
        { title: "cancelled", value: "cancelled" },
      ],
      editable: false,
      notOnFilter: false,
    },
    {
      title: "responsible-for-the-case",
      id: "supervisorName",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "lawyer-name",
      id: "lawyerName",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "consumer-name",
      id: "consumerName",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "case-actions",
      id: "_",
      type: TableTypes.select,
      isActionsList: true,
      list: [
        { title: "chooses-action", value: "_" },
        { title: "transfer-to-lawyer", value: RowActions.transferToLawyer },
        // {
        //   title: "transfer-to-reconciliation",
        //   value: RowActions.transferToReconciliation,
        // },
        {
          title: "transfer-to-supervisor",
          value: RowActions.transferToSupervisor,
        },
        {
          title: "inquiry-ending",
          value: RowActions.caseEnding,
          fontColor: "var(--secondary-color)",
        },
      ],
      editable: false,
      notOnFilter: true,
    },
  ];
  dataSource!: any;
  isDataLoading = false;
  
  filters: any = {
    limit: 10,
    offset: 0,
    order: ["created_at DESC"],
    where: {
      and: [],
    },
  };

  dialogSub$!: Subscription;
  searchFilter!: any;
  filterUsersCases!: any;
  filterUsersCasesObj: any = {};
  casesList!: any[];
  searchKeyword: string = "";
  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _translate: TranslateService,
    private _router: Router
  ) {
    super();
    this._tableService.tableUrl = "admin/inquiries";
  }

  async ngOnInit() { }

  async getData(filters?: Filter) {
    this.isDataLoading = true;
    try {
      this._tableService.tableUrl = "admin/inquiries";
      this.dataSource = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((userCase) => {
        return {
          ...userCase,
          supervisorName: userCase.userSupervisor?.username,
          caseTitle: userCase.caseType?.title,
          lawyerName: userCase.userAssignee?.username
            ? // &&
              //   userCase.userAssignee?.roleId == 5
              userCase.userAssignee?.username
            : "-",
          consumerName: userCase.userOwner?.username
            ? userCase.userOwner?.username
            : "-",
        };
      });

      // Get list of cases types
      // this._tableService.tableUrl = "admin/cases";
      // const casesList = await this._tableService.getData<any>().toPromise();
      // this.casesList = casesList;

    } catch (err) {
      // console.error(err);
    }

    this.isDataLoading = false;
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  async handleRowActions(data: { action: RowActions; row: any }) {
    const { action, row } = data;
    const caseId = row.id;

    if (action === RowActions.caseEnding) {
      await this.handleFinishCaseDialog(caseId);
    } else {
      let assigneeType: UserRoles;

      if (action === RowActions.transferToLawyer) {
        assigneeType = UserRoles.lawyer;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToReconciliation) {
        assigneeType = UserRoles.conciliator;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToSupervisor) {
        assigneeType = UserRoles.supervisor;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      }
    }
  }

  async handleAssignCaseDialog(assigneeType: UserRoles, caseId: Number) {
    const dialogData: null | { assigneeIds: number[] } = await this._dialog
      .open(AssignDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "white-dialog",
        autoFocus: false,
        data: {
          assigneeType,
        },
      })
      .afterClosed()
      .toPromise();

    if (!dialogData) return;
    const { assigneeIds } = dialogData;

    assigneeType === UserRoles.supervisor
      ? await this._http
          .patch(
            `${environment.apiHost}admin/inquiries/${caseId}`,
            {
              supervisor_id: assigneeIds[0],
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise()
      : await this._http
          .post(
            `${environment.apiHost}admin/inquiry-assignees/reassign`,
            {
              assignee_ids: assigneeIds,
              inquiry_id: caseId,
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise();

    this._tableService.redirect(this.url);
  }

  async handleFinishCaseDialog(caseId: number) {
    const isFinish: boolean = await this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this._translate.instant(
            "confirm-dialog.are-you-want-finish-case"
          ),
          icon: "close",
        },
      })
      .afterClosed()
      .toPromise();

    if (isFinish) {
      try {
        await this._http
          .patch(
            `${environment.apiHost}admin/response-inquiries/${caseId}`,
            {
              status: "FINISH",
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise();

        this._tableService.redirect(this.url);
      } catch (err) {
        // console.error(err);
      }
    }
  }

  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;
    if (!value) this._dataTableComponent.paginator.firstPage();
    this.searchKeyword = value;
    this.filters.where.and = this.filters.where.and.filter(
      (item) => !item.keyword
    );
    if (value.length > 0) {
      this.filters.where.and.push({ keyword: value });
    } else {
      if (this.filters.where.and.length === 0) {
        this._router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this._router.navigate(["user-inquiries"]);
          });
      }
    }

    this._dataTableComponent.backendFilter = this.filters;

    this.getData(this._dataTableComponent.backendFilter);

    this._dataTableComponent.tableDataLength = (
      await this._tableService
        .getDataCount(this._dataTableComponent.backendFilter.where)
        .toPromise()
    ).count;

    this.isDataLoading = false;
  }

  // handle filter dialog
  async handleCustomFilter(data: any) {
    if (data == "customFilter") {
      this.dialogSub$ = this._dialog
        .open(FilterUsersDialogComponent, {
          direction: "rtl",
          panelClass: "save-case-dialog",
          data: {
            displayData: this.displayedColumns,
            list: "users-inquiries",
            casesTypes: this.casesList,
            inquiryStatus: [ 
              { title: "all", value: "all"},
              { title: "inquiry-new", value: "pending"},
              { title: "active", value: "active"},
              { title: "inquiry-closed", value: "closed"},
              { title: "rejected", value: "rejected"},
            ],
          },
        })
        .afterClosed()
        .subscribe(async (filterUsers: any) => {
          if (filterUsers) {
            this.isDataLoading = true;
            this.handleFilter(filterUsers);
          }
        });
    }
  }

  // handle filteration
  async handleFilter(obj: any) {
    this.filterUsersCases = obj;
    let body: any = { and: [] };
    if (obj.dateToValue && obj.dateFromValue) {
      body.and = [
        { created_at: { lt: obj.dateToValue } },
        { created_at: { gt: obj.dateFromValue } },
      ];
    }
    if (obj.inquiryStatus && obj.inquiryStatus !== 'all') {
      body.and.push({ status: obj.inquiryStatus });
    }

    if (this.searchKeyword.length > 0) {
      body.and.push({ keyword: this.searchKeyword });
    }

    for (let item of body.and) {
      if (item.created_at) {
        if (item.created_at.lt) {
          this.filterUsersCasesObj.dateToValue = item.created_at.lt;
        }
        if (item.created_at.gt) {
          this.filterUsersCasesObj.dateFromValue = item.created_at.gt;
        }
      }
      if (item.status && item.status !== 'all') {
        this.filterUsersCasesObj.status = item.status;
      }
    }

    this.filters["where"] = {
      ...this.filters["where"],
      ...body,
    };

    this._dataTableComponent.backendFilter = this.filters;
    this.getData(this.filters);
    this._dataTableComponent.tableDataLength = (
      await this._tableService.getDataCount(this.filters["where"]).toPromise()
    ).count;
    this.isDataLoading = false;
  }

  //remove filter - type and update filter and display it from api
  removeFilter(value: any) {
    if (value == "dateFromValue" || value == "dateToValue") {
      delete this.filterUsersCasesObj["dateFromValue"];
      delete this.filterUsersCasesObj["dateToValue"];
      delete this.filterUsersCases["dateFromValue"];
      delete this.filterUsersCases["dateToValue"];
    } else if (value == "case_id") {
      delete this.filterUsersCasesObj["case_id"];
      delete this.filterUsersCases["caseType"];
    } else if (value == "status") {
      delete this.filterUsersCasesObj["status"];
      delete this.filterUsersCases["inquiryStatus"];
    } else {
      delete this.filterUsersCasesObj[value];
      delete this.filterUsersCases[value];
    }

    if (Object.keys(this.filterUsersCasesObj).length === 0) {
      this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this._router.navigate(["user-inquiries"]);
      });
    } else {
      this.handleFilter(this.filterUsersCases);
    }
  }

  getCaseById(id: number) {
    return this.casesList.find((item) => {
      return item.id === id;
    });
  }

  isFilterObjEmpty() {
    return Object.keys(this.filterUsersCasesObj).length === 0;
  }

  ngOnDestroy(): void {
    this.dialogSub$?.unsubscribe();
  }
}
