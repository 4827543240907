import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ComplaintsPageComponent } from "./pages/complaints-page/complaints-page.component";
import { MatIconModule } from "@angular/material/icon";
import { SharedModule } from "../shared/shared.module";
import { ComplaintDetailsDialogComponent } from "./dialogs/complaint-details-dialog/complaint-details-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [ComplaintsPageComponent, ComplaintDetailsDialogComponent],
  imports: [SharedModule, CommonModule, MatIconModule, MatDialogModule],
})
export class ComplaintsModule {}
