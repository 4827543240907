import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ThemeDirective } from "./directive/theme.directive";
import { PlatformModule } from "@angular/cdk/platform";
import { MatDialogModule } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { ImageDialog } from "./image-dialog/image-dialog.component";
import { ImageEdit } from "./image-edit/image-edit.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { TranslateModule } from "@ngx-translate/core";
import { DeleteConfirmDialog } from "./delete-confirm-dialog/delete-confirm-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonLoadingDirective } from "./directive/mat-btn-loading.directive";
import { MatPageLoadingDirective } from "./directive/mat-page-loading.directive";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { KNgDataTableModule } from "@kortobaa-front/k-ng-datatable";
import { kNgDataTablePkgConfig } from "../config";
import { ArabicDatePipe } from "./pipes/arabic-date-pipe.pipe";
import { HijriDatePipe } from "./pipes/hijri-date";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDividerModule,
    ImageCropperModule,
    TranslateModule,
    KNgDataTableModule.forRoot(kNgDataTablePkgConfig),
  ],
  declarations: [
    ThemeDirective,
    ImageDialog,
    ImageEdit,
    DeleteConfirmDialog,
    MatButtonLoadingDirective,
    MatPageLoadingDirective,
    ConfirmDialogComponent,
    ArabicDatePipe,
    HijriDatePipe,
  ],
  exports: [
    KNgDataTableModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ThemeDirective,
    PlatformModule,
    ImageDialog,
    ImageEdit,
    DeleteConfirmDialog,
    MatButtonLoadingDirective,
    MatPageLoadingDirective,
    ConfirmDialogComponent,
    ArabicDatePipe,
    HijriDatePipe
  ],
  providers: [],
  entryComponents: [],
})
export class SharedModule {}
