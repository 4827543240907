import { Component, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import {
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  TableService,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { AssignDialogComponent } from "../../user-cases/assign-dialog/assign-dialog.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { UserRoles } from "src/app/shared/models/interfaces";
import { environment } from "src/environments/environment";
import * as moment from "moment-timezone";


enum RowActions {
  transferToLawyer = "transferToLawyer",
  transferToReconciliation = "transferToReconciliation",
  transferToSupervisor = "transferToSupervisor",
  caseEnding = "caseEnding",
}
@Component({
  selector: 'app-usercases-types-reports',
  templateUrl: './usercases-types-reports.component.html',
  styleUrls: ['./usercases-types-reports.component.scss']
})
export class UsercasesTypesReportsComponent extends DataTableComponentBase
  implements OnInit {
  moduleName = "reports";
  url = "cases-types-reports";
  filters: Filter = {
    where: {
      and: [
        {
          created_at: {
            gt: new Date(
              new Date().setDate(new Date().getDate() - 7)
            ).toISOString(),
          },
        },
        { created_at: { lt: new Date(Date.now()).toISOString() } },
      ],
    },
  };

  displayedColumns: TableSchema[] = [
    {
      title: "case-type-name",
      id: "caseType",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-cases",
      id: "totalCases",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-new-cases",
      id: "totalCasesNew",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-ongoing-cases",
      id: "totalOngoingCases",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-temp-closed-cases",
      id: "totalCasestempClosed",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-finish-cases",
      id: "totalCasesFinished",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-closed-cases",
      id: "totalCasesClosed",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "total-cancelled-cases",
      id: "totalCasesCancelled",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _translate: TranslateService
  ) {
    super();
    this._tableService.tableUrl = "admin/cases-statistical-report";
  }

  async ngOnInit() { }

  async getData(filters?: Filter) {
    filters["where"] = this.filters.where;
    delete filters.order;
    this.isDataLoading = true;
    try {
      this._tableService.tableUrl = "admin/cases-statistical-report";
      let data = (
        await this._tableService.getData<any>(filters).toPromise()
      );
      this.dataSource = data
    } catch (err) {
      // console.error(err);
    }

    this.isDataLoading = false;
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = "admin/cases-statistical-report";
      let data = (
        await this._tableService.getData<any>({ where: this.filters.where }).toPromise()
      );
      await this._dataTableComponent.downloadAllData(data);
    } catch (err) {
      // console.error(err);
    }
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  async handleRowActions(data: { action: RowActions; row: any }) {
    const { action, row } = data;
    const caseId = row.case_id;

    if (action === RowActions.caseEnding) {
      await this.handleFinishCaseDialog(caseId);
    } else {
      let assigneeType: UserRoles;

      if (action === RowActions.transferToLawyer) {
        assigneeType = UserRoles.lawyer;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToReconciliation) {
        assigneeType = UserRoles.conciliator;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToSupervisor) {
        assigneeType = UserRoles.supervisor;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      }
    }
  }

  async handleAssignCaseDialog(assigneeType: UserRoles, caseId: Number) {
    const dialogData: null | { assigneeIds: number[] } = await this._dialog
      .open(AssignDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "white-dialog",
        autoFocus: false,
        data: {
          assigneeType,
        },
      })
      .afterClosed()
      .toPromise();

    if (!dialogData) return;
    const { assigneeIds } = dialogData;

    assigneeType === UserRoles.supervisor
      ? await this._http
        .patch(
          `${environment.apiHost}admin/user-cases/${caseId}`,
          {
            supervisor_id: assigneeIds[0],
          },
          {
            headers: getHeadersWithToken(),
          }
        )
        .toPromise()
      : await this._http
        .post(
          `${environment.apiHost}admin/usercase-reassign-assignees`,
          {
            assignee_ids: assigneeIds,
            assignees_type: assigneeType,
            usercase_id: caseId,
          },
          {
            headers: getHeadersWithToken(),
          }
        )
        .toPromise();

    this._tableService.redirect(this.url);
  }

  async handleFinishCaseDialog(caseId: number) {
    const isFinish: boolean = await this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this._translate.instant(
            "confirm-dialog.are-you-want-finish-case"
          ),
          icon: "close",
        },
      })
      .afterClosed()
      .toPromise();

    if (isFinish) {
      try {
        await this._http
          .patch(
            `${environment.apiHost}admin/response-user-cases/${caseId}`,
            {
              status: "FINISH",
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise();

        this._tableService.redirect(this.url);
      } catch (err) {
        // console.error(err);
      }
    }
  }

  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;
    // console.log(value);

    const searchResult = await this._http
      .post<{ count: number; result: any[] }>(
        `${environment.apiHost}admin/usercase-search`,
        {
          keyword: value,
          skip: 0,
        },
        {
          headers: getHeadersWithToken(),
        }
      )
      .toPromise();

    // console.log(searchResult);

    this.dataSource = searchResult.result.map((userCase) => ({
      ...userCase,
      supervisorName: `المشرف: ${userCase.userSupervisor?.username}`,
      caseImage: userCase.caseType.image_url,
    }));

    // TODO: not complete solution because pagination not works
    this._dataTableComponent.tableDataLength = searchResult.count;

    this.isDataLoading = true;
  }

  async filterByDate(value: any) {
    let startDate = moment(value.startDate).add(3, 'h').toDate()?.toISOString();
    let endDate = moment(value.endDate).add(3, 'h').toDate()?.toISOString();
    this.filters["where"] = {
      and: [{ created_at: { gt: startDate } }, { created_at: { lt: endDate } }],
    };
    this.getData(this.filters);
  }
}
