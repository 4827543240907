import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

import {
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  TableService,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { DataService } from "src/app/shared/services/data.service";
import { AssignDialogComponent } from "../../user-cases/assign-dialog/assign-dialog.component";
import { ConfirmDialogComponent } from "src/app/shared/components/confirm-dialog/confirm-dialog.component";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";
import { UserRoles } from "src/app/shared/models/interfaces";
import { environment } from "src/environments/environment";
import * as moment from "moment-timezone";
import {
  chooseStartDate,
  lawyerPassedHoursColorCode,
  passedHoursColorCode,
} from "src/app/shared/helpers/business-hours-passed";
import { FilterUsersDialogComponent } from "src/app/users/dialogs/filter-users-dialog/filter-users-dialog.component";
import { Subscription } from "rxjs";

enum RowActions {
  transferToLawyer = "transferToLawyer",
  transferToReconciliation = "transferToReconciliation",
  transferToSupervisor = "transferToSupervisor",
  caseEnding = "caseEnding",
}

@Component({
  selector: "app-usercases-status-reports",
  templateUrl: "./usercases-status-reports.component.html",
  styleUrls: ["./usercases-status-reports.component.scss"],
})
export class UsercasesStatusReportsComponent
  extends DataTableComponentBase
  implements OnInit, OnDestroy
{
  moduleName = "reports";
  url = "cases-status-reports";
  filters: Filter = {
    where: {
      and: [
        {
          created_at: {
            gt: new Date(
              new Date().setDate(new Date().getDate() - 7)
            ).toISOString(),
          },
        },
        { created_at: { lt: new Date(Date.now()).toISOString() } },
      ],
    },
  };

  displayedColumns: TableSchema[] = [
    {
      title: "case-code",
      id: "application_no",
      type: TableTypes.number,
      editable: false,
      notOnFilter: true,
    },
    {
      title: "case-type-name",
      id: "caseType",
      type: TableTypes.text,
      editable: false,
      notOnFilter: true,
    },
    // {
    //   title: "case-image",
    //   id: "caseImage",
    //   type: TableTypes.imageList,
    //   editable: false,
    //   notOnFilter: true,
    // },
    {
      title: "responsible-for-the-case",
      id: "supervisorName",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "lawyer-name",
      id: "lawyerName",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "case-status",
      id: "status",
      type: TableTypes.select,
      list: [
        { title: "declined", value: "declined", fontColor: "#0F6E5F" },
        { title: "closed", value: "closed", fontColor: "#AB1212" },
        { title: "finished", value: "finished", fontColor: "#3F3A3A" },
        { title: "cancelled", value: "cancelled" },
        { title: "new", value: "new" },
        { title: "pending", value: "pending", fontColor: "#E5B46D" },
        { title: "modify", value: "modify" },
        { title: "active", value: "active", fontColor: "#1246AB" },
      ],
      editable: false,
      notOnFilter: true,
    },
    {
      title: "assignment-delay",
      id: "assignmentDelay",
      type: TableTypes.boolean,
      editable: false,
    },
    {
      title: "acceptance-delay",
      id: "acceptanceDelay",
      type: TableTypes.boolean,
      editable: false,
    },
    {
      title: "closure-reason",
      id: "reason",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "service_name",
      id: "serviceName",
      type: TableTypes.text,
      editable: false,
    },
    // {
    //   title: "lawsuit_status",
    //   id: "lawsuit_status",
    //   type: TableTypes.select,
    //   list: [
    //     { title: "primary-ruling", value: "حكم ابتدائى" },
    //     { title: "closed", value: "استئناف" },
    //   ],
    //   editable: false,
    // },
    // {
    //   title: "responsible-for-the-case",
    //   id: "supervisorName",
    //   type: TableTypes.text,
    //   editable: false,
    //   notOnFilter: true,
    // },
    // {
    //   title: "created-date-of-usercase",
    //   id: "created_at",
    //   type: TableTypes.datetime,
    //   editable: false,
    //   notOnFilter: false,
    // },
    // {
    //   title: "case-actions",
    //   id: "_",
    //   type: TableTypes.select,
    //   isActionsList: true,
    //   list: [
    //     { title: "chooses-action", value: "_" },
    //     { title: "transfer-to-lawyer", value: RowActions.transferToLawyer },
    //     {
    //       title: "transfer-to-reconciliation",
    //       value: RowActions.transferToReconciliation,
    //     },
    //     {
    //       title: "transfer-to-supervisor",
    //       value: RowActions.transferToSupervisor,
    //     },
    //     {
    //       title: "case-ending",
    //       value: RowActions.caseEnding,
    //       fontColor: "var(--secondary-color)",
    //     },
    //   ],
    //   editable: false,
    // },
  ];
  dataSource;
  isDataLoading = false;
  dialogSub$!: Subscription;
  filterUsersCases!: any;
  filterUsersCasesObj: any = {};

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dataService: DataService,
    private _dialog: MatDialog,
    private _http: HttpClient,
    private _translate: TranslateService
  ) {
    super();
    this._tableService.tableUrl = "admin/user-cases";
  }

  async ngOnInit() {}

  async getData(filters?: Filter) {
    filters["where"] = this.filters.where;
    this.isDataLoading = true;
    try {
      this._tableService.tableUrl = "admin/user-cases";
      this.dataSource = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((userCase) => {
        return {
          ...userCase,
          supervisorName: userCase.userSupervisor?.username ?? "-",
          lawyerName: userCase.userAssignee?.username ?? "-",
          caseImage: userCase.caseType?.image_url,
          caseType: userCase.caseType?.title,
          reason: userCase.reason ?? "-",
          serviceName: userCase.service?.title ?? "-",
          assignmentDelay: this.isActionDelayed(
            "assignment",
            userCase.created_at,
            userCase.reopeningDate,
            userCase
          ),
          acceptanceDelay: userCase.assignmentDate
            ? this.isActionDelayed(
                "assignmentAcceptance",
                userCase.assignmentDate,
                userCase.reopeningDate,
                userCase
              )
            : false,
        };
      });
    } catch (err) {
      // console.error(err);
    }

    this.isDataLoading = false;
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = "admin/user-cases";
      let exportData = (
        await this._tableService
          .getData<any>({ where: this.filters.where })
          .toPromise()
      ).map((userCase) => {
        return {
          ...userCase,
          supervisorName: `المشرف: ${userCase.userSupervisor?.username}`,
          caseImage: userCase.caseType?.image_url,
          caseType: userCase.caseType?.title,
        };
      });
      await this._dataTableComponent.downloadAllData(exportData);
    } catch (err) {
      // console.error(err);
    }
  }

  getDataForAction(data) {
    this._tableService.SaveItem(data).subscribe(
      (row: any) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        this._dataService.notification(error.error.error.message);
      }
    );
  }

  async handleRowActions(data: { action: RowActions; row: any }) {
    const { action, row } = data;
    const caseId = row.case_id;

    if (action === RowActions.caseEnding) {
      await this.handleFinishCaseDialog(caseId);
    } else {
      let assigneeType: UserRoles;

      if (action === RowActions.transferToLawyer) {
        assigneeType = UserRoles.lawyer;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToReconciliation) {
        assigneeType = UserRoles.conciliator;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      } else if (action === RowActions.transferToSupervisor) {
        assigneeType = UserRoles.supervisor;
        await this.handleAssignCaseDialog(assigneeType, caseId);
      }
    }
  }

  async handleAssignCaseDialog(assigneeType: UserRoles, caseId: Number) {
    const dialogData: null | { assigneeIds: number[] } = await this._dialog
      .open(AssignDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "white-dialog",
        autoFocus: false,
        data: {
          assigneeType,
        },
      })
      .afterClosed()
      .toPromise();

    if (!dialogData) return;
    const { assigneeIds } = dialogData;

    assigneeType === UserRoles.supervisor
      ? await this._http
          .patch(
            `${environment.apiHost}admin/user-cases/${caseId}`,
            {
              supervisor_id: assigneeIds[0],
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise()
      : await this._http
          .post(
            `${environment.apiHost}admin/usercase-reassign-assignees`,
            {
              assignee_ids: assigneeIds,
              assignees_type: assigneeType,
              usercase_id: caseId,
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise();

    this._tableService.redirect(this.url);
  }

  async handleFinishCaseDialog(caseId: number) {
    const isFinish: boolean = await this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._translate.currentLang == "ar" ? "rtl" : "ltr",
        panelClass: "confirm-dialog",
        data: {
          content: this._translate.instant(
            "confirm-dialog.are-you-want-finish-case"
          ),
          icon: "close",
        },
      })
      .afterClosed()
      .toPromise();

    if (isFinish) {
      try {
        await this._http
          .patch(
            `${environment.apiHost}admin/response-user-cases/${caseId}`,
            {
              status: "FINISH",
            },
            {
              headers: getHeadersWithToken(),
            }
          )
          .toPromise();

        this._tableService.redirect(this.url);
      } catch (err) {
        // console.error(err);
      }
    }
  }

  async handleSearchChange(value: string) {
    // TODO: refactor all this logic, it's just a workaround to manipulate the data table component directly because of the poor API
    this.isDataLoading = true;

    const searchResult = await this._http
      .post<{ count: number; result: any[] }>(
        `${environment.apiHost}admin/usercase-search`,
        {
          keyword: value,
          skip: 0,
        },
        {
          headers: getHeadersWithToken(),
        }
      )
      .toPromise();

    this.dataSource = searchResult.result.map((userCase) => ({
      ...userCase,
      supervisorName: `المشرف: ${userCase.userSupervisor?.username}`,
      caseImage: userCase.caseType.image_url,
    }));

    // TODO: not complete solution because pagination not works
    this._dataTableComponent.tableDataLength = searchResult.count;

    this.isDataLoading = true;
  }

  async filterByDate(value: any) {
    let startDate = moment(value.startDate).add(3, "h").toDate()?.toISOString();
    let endDate = moment(value.endDate).add(3, "h").toDate()?.toISOString();
    this.filters["where"] = {
      and: [{ created_at: { gt: startDate } }, { created_at: { lt: endDate } }],
    };
    this.getData(this.filters);
  }

  isActionDelayed(
    action: string,
    mandatoryDate: string,
    optionalDate: string | null,
    userCase: any
  ): boolean {
    const startDate = chooseStartDate(mandatoryDate, optionalDate); // handle re-opened cases
    if (action === "assignment") {
      const colorCode = passedHoursColorCode(startDate);
      return (
        !userCase.assignmentDate &&
        userCase.status !== "pending" &&
        colorCode === "critical"
      );
    } else {
      const colorCode = lawyerPassedHoursColorCode(
        startDate,
        userCase.lawyerAcceptanceDate
      );
      return colorCode === "critical";
    }
  }

  // handle filter dialog
  async handleCustomFilter(data: any) {
    if (data == "customFilter") {
      this.dialogSub$ = this._dialog
        .open(FilterUsersDialogComponent, {
          direction: "rtl",
          panelClass: "save-case-dialog",
          data: {
            list: "usercases-status",
            serviceName: [
              {
                title: "خدمات الترافع فقط",
                value: "khdmat_altrafa_amam_almhkmh",
              },
              {
                title: "الخدمات الكتابية فقط",
                value: "alkhdmat_alqanwnyh_alktabyh_walajraeyh",
              },
              {
                title: "كلاهما, خدمات الترافع و الخدمات الكتابية",
                value: "all",
              },
            ],
          },
        })
        .afterClosed()
        .subscribe(async (filterUsersCases: any) => {
          if (filterUsersCases) {
            this.isDataLoading = true;
            this.handleFilter(filterUsersCases);
          }
        });
    }
  }

  // handle filteration
  async handleFilter(obj: any) {
    this.filterUsersCases = obj;
    let body: any = { and: [] };

    this.filters.where.and = this.filters.where.and.filter(
      (obj) => !Object.keys(obj).includes("service")
    );
    if (obj.serviceName && obj.serviceName !== undefined && obj.serviceName !== "all") {
      body.and.push({ service: obj.serviceName });
    }

    for (let item of body.and) {
      if (item.service !== undefined) {
        this.filterUsersCasesObj.service = item.service;
      }
    }

    this.filters["where"].and = [...this.filters["where"].and, ...body.and];

    this._dataTableComponent.backendFilter = this.filters;
    this.getData(this.filters);
    this._dataTableComponent.tableDataLength = (
      await this._tableService.getDataCount(this.filters["where"]).toPromise()
    ).count;
    this.isDataLoading = false;
  }

  ngOnDestroy(): void {
    this.dialogSub$?.unsubscribe();
  }
}
