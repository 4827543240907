import { Component, Input, OnInit } from "@angular/core";

import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";

import "moment/locale/ar";

import { UserInputMode, UserInputType } from "src/app/shared/models/interfaces";

export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-user-data-input",
  templateUrl: "./user-data-input.component.html",
  styleUrls: ["./user-data-input.component.scss"],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: "ar" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class UserDataInputComponent implements OnInit {
  readonly UserInputMode = UserInputMode;
  readonly UserInputType = UserInputType;

  readonly MAXIMUM_IMAGE_SIZE = 2097152;
  isImageInvalid = false;
  actualImg: string | ArrayBuffer | null = null;

  control: FormControl;
  @Input() formGroupParent: FormGroup;
  @Input() mode: UserInputMode;
  @Input() userData: string | boolean | number;
  @Input() inputType: UserInputType;
  @Input() controlName: string;
  @Input() maxValue: string;
  @Input() readonly = false;
  @Input() listData: string[];
  checkedValues = [];
  @Input() isHijriDate: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.control = <FormControl>this.formGroupParent.get(this.controlName);

    if (
      this.inputType === UserInputType.CHECK &&
      this.control.value.length &&
      Array.isArray(this.control.value)
    )
      this.checkedValues = this.control.value;
  }

  onImgUploaded(event) {
    const uploadedImg = event.target.files[0];
    if (!uploadedImg) return;

    // Not allow to upload image which size is equal or more than 2MB
    this.isImageInvalid = uploadedImg.size >= this.MAXIMUM_IMAGE_SIZE;

    this.control.setValue(uploadedImg);
    const reader = new FileReader();
    reader.readAsDataURL(uploadedImg);
    reader.onload = (_event) => {
      this.actualImg = reader.result;
    };
  }

  removeImg() {
    this.control.setValue(null);
    this.actualImg = null;
  }

  get isUserDataBoolean() {
    return this.userData == 1 || this.userData == 0;
  }

  isControlRequired(field: string) {
    const form_field = this.formGroupParent.get(field);
    if (!form_field.validator) {
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator && validator.required;
  }

  //check checkbox array to push or pop any checked or unchecked value
  checkValueHandler(event: any) {
    const isChecked = event.target.getAttribute("aria-checked") == "true";
    const value = event.target.getAttribute("value");
    if (isChecked) this.checkedValues.push(value);
    if (!isChecked)
      this.checkedValues = this.checkedValues.filter((item) => {
        return item != value;
      });

    this.control.setValue(this.checkedValues);
  }

  addToMultipleText(input: HTMLInputElement): void {
    const value = input.value;

    // Add our fruit
    if ((value || "").trim()) {
      this.control.value.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = "";
    }

    this.control.updateValueAndValidity();
  }

  removeFromMultipleText(value: any): void {
    const index = this.control.value.indexOf(value);

    if (index >= 0) {
      this.control.value.splice(index, 1);
    }

    this.control.updateValueAndValidity();
  }

  //valid pattern
  validatePhone() {
    const phoneValue = this.control.value.replace(/^05/, "5");
    this.control.setValue(phoneValue, {
      emitModelToViewChange: false,
    });
  }
}
