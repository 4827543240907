import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-complaint-details-dialog",
  templateUrl: "./complaint-details-dialog.component.html",
  styleUrls: ["./complaint-details-dialog.component.scss"],
})
export class ComplaintDetailsDialogComponent implements OnInit {
  galleryArr: any = [];

  constructor(
    public dialogRef: MatDialogRef<ComplaintDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close();
  }

    // open gallery to preview
    openGallery(attachment: any, arr: any) {
      window.open(attachment.url, "_blank");
      // this.galleryArr = [];
      // this.galleryArr.push(attachment.url);
      // arr.filter((item: any) => {
      //   if (item.fileType == "image" && item.url != attachment.url) {
      //     this.galleryArr.push(item.url);
      //   }
      // });
      // if (this.galleryArr.length >= 1) {
        // this._dialog.open(PreviewImagesComponent, {
        //   panelClass: "attachment-dialog",
        //   width: "100%",
        //   maxWidth: "100%",
        //   data: {
        //     arr: this.galleryArr,
        //   },
        // });
      // }
    }
  
    // open pdf in new tab
    openPdf(pdfUrl: any) {
      window.open(pdfUrl, "_blank");
    }
}
