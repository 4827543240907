import { NgModule } from "@angular/core";
import { ReportsComponent } from "./reports/reports.component";
import { UsercasesStatusReportsComponent } from "./usercases-status-reports/usercases-status-reports.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatListModule } from "@angular/material/list";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MatTreeModule } from "@angular/material/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

import { SharedModule } from "../shared/shared.module";
import { LawyersReportsComponent } from './lawyers-reports/lawyers-reports.component';
import { ConciliatorReportsComponent } from './conciliator-reports/conciliator-reports.component';
import { UsercasesTypesReportsComponent } from './usercases-types-reports/usercases-types-reports.component';
import { EvaluationsReportComponent } from './evaluations-report/evaluations-report.component';
import { ViewEvaluationDialogComponent } from './view-evaluation-dialog/view-evaluation-dialog.component';
import { ReasonsCasesReportsComponent } from "./reasons-cases-reports/reasons-cases-reports.component";
import { EvaluationsInquiriesReportComponent } from "./evaluations-inquiries-report/evaluations-inquiries-report.component";
import { ServicesStatisticsReportsComponent } from './services-statistics-reports/services-statistics-reports.component';
import { InquiriesTypesReportsComponent } from './inquiries-types-reports/inquiries-types-reports.component';
import { WritingCasesTypesReportsComponent } from './writing-cases-types-reports/writing-cases-types-reports.component';
import { UserInquiriesStatusReportsComponent } from './user-inquiries-status-reports/user-inquiries-status-reports.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatFormFieldModule,
    MatPaginatorModule,
    MatInputModule,
    MatSortModule,
    MatGridListModule,
    MatToolbarModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatListModule,
    TranslateModule,
    MatTreeModule,
    MatAutocompleteModule,
  ],
  declarations: [ReportsComponent, UsercasesStatusReportsComponent, LawyersReportsComponent, ConciliatorReportsComponent, UsercasesTypesReportsComponent,
    EvaluationsReportComponent, ViewEvaluationDialogComponent, ReasonsCasesReportsComponent, EvaluationsInquiriesReportComponent, ServicesStatisticsReportsComponent, InquiriesTypesReportsComponent, WritingCasesTypesReportsComponent, UserInquiriesStatusReportsComponent],
  exports: [UsercasesStatusReportsComponent],
})
export class ReportsModule { }
