import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

import { TableService } from "@kortobaa-front/k-ng-datatable";

import {
  RolesId,
  SavedUserData,
  UserData,
  UserInputMode,
  UserInputType,
  UserRoles,
} from "src/app/shared/models/interfaces";
import { HandleUsersService } from "../serviecs/handle-users.service";
import { addDuplicatedError } from "src/app/shared/helpers/formControlers";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { getHeadersWithToken } from "src/app/shared/helpers/getHeadersWithToken";

@Component({
  selector: "app-save-user",
  templateUrl: "./save-user.component.html",
  styleUrls: ["./save-user.component.scss"],
})
export class SaveUserComponent implements OnInit {
  readonly SavedUserData = SavedUserData;
  readonly UserInputMode = UserInputMode;
  readonly UserInputType = UserInputType;
  readonly UserRoles = UserRoles;

  userRole: UserRoles;
  userName: string;
  userId: number;
  userData: UserData = {} as UserData;
  isLoading = false;
  isAddNewUser = true;
  editableSection: SavedUserData;
  isAddingNewUser = false;
  isEditingUser = false;
  usercasesCount = 0;
  userInquiriesCount = 0;
  userCases: any = [];
  userInquries: any = [];
  isViewing: boolean = false;
  currentCopiedText!: string;
  isNewUserPage: boolean = false;
  userDataOptional!: any;
  readonly today = new Date();
  nationality!: string;
  saveUserForm = new FormGroup({
    [SavedUserData.PERSONAL_DATA]: new FormGroup({
      firstName: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
        ),
      ]),
      secondName: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
        ),
      ]),
      thirdName: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
        ),
      ]),
      lastName: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
        ),
      ]),
    }),
    [SavedUserData.IDENTITY_DATA]: new FormGroup({
      nid: new FormControl("", [
        Validators.required,
        Validators.pattern(
          "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
        ),
      ]),
    }),
    [SavedUserData.CONTACT_DATA]: new FormGroup({
      email: new FormControl("", [Validators.required, Validators.email]),
      phone: new FormControl("", [Validators.required]),
    }),
    [SavedUserData.OUR_CUSTOMER_DATA]: new FormGroup({
      isDisabled: new FormControl(1, [Validators.required]),
    }),
  });

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _tableService: TableService,
    private _handleUsersService: HandleUsersService,
    private _fb: FormBuilder,
    private _http: HttpClient
  ) { }

  async ngOnInit(): Promise<void> {
    this.isNewUserPage = this._router.url == "/new-user/user" ? true : false;
    this._activatedRoute.queryParams.subscribe(({ userName }) => {
      this.userName = userName;
    });

    this.userRole = this._activatedRoute.snapshot.paramMap.get(
      "userRole"
    ) as UserRoles;

    const ourCustomerDataForm = this.saveUserForm.controls[
      SavedUserData.OUR_CUSTOMER_DATA
    ] as FormGroup;

    const ourPersonalDataForm = this.saveUserForm.controls[
      SavedUserData.PERSONAL_DATA
    ] as FormGroup;

    const ourIdentityDataForm = this.saveUserForm.controls[
      SavedUserData.IDENTITY_DATA
    ] as FormGroup;

    if ([UserRoles.lawyer, UserRoles.conciliator].includes(this.userRole)) {
      ourCustomerDataForm.addControl(
        "assignedCases",
        new FormControl("", [Validators.required])
      );

      ourCustomerDataForm.addControl(
        "signedContractType",
        new FormControl("", [Validators.required])
      );

      ourCustomerDataForm.addControl(
        "perpetualMechanism",
        new FormControl("presence", [Validators.required])
      );
    }
    if (this.userRole === UserRoles.conciliator) {
      this.saveUserForm.addControl(
        SavedUserData.PROFESSIONAL_DATA,
        new FormGroup({
          profession: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,50}$"
            ),
          ]),
          qualification: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,100}$"
            ),
          ]),
          scientificExpertise: new FormControl("", [Validators.required]),
          experienceDuration: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,200}$"
            ),
          ]),
        })
      );
    }
    if (this.userRole === UserRoles.lawyer) {
      this.saveUserForm.addControl(
        SavedUserData.LAWYER_DATA,
        new FormGroup({
          licenseNumber: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,50}$"
            ),
          ]),
          licenseDate: new FormControl("", [Validators.required]),
          licenseImage: new FormControl("", [Validators.required]),
          qualification: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,100}$"
            ),
          ]),
          scientificExpertise: new FormControl("", [Validators.required]),
          experienceDuration: new FormControl("", [
            Validators.required,
            Validators.pattern(
              "[/\n/sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,200}$"
            ),
          ]),
          trainingCourses: new FormControl([], [Validators.required]),
        })
      );
      ourPersonalDataForm
        .get("secondName")
        .setValidators([
          Validators.pattern(
            "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
          ),
        ]);
      ourPersonalDataForm
        .get("thirdName")
        .setValidators([
          Validators.pattern(
            "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
          ),
        ]);
      ourPersonalDataForm
        .get("lastName")
        .setValidators([
          Validators.pattern(
            "^[A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9][A-Za-z\u0621-\u064A\0-9]{0,49}$"
          ),
        ]);

      ourCustomerDataForm.addControl(
        "service",
        new FormControl([], [Validators.required])
      );
    }
    if (this.userRole === UserRoles.supervisor) {
      ourCustomerDataForm.addControl(
        "autoAssign",
        new FormControl("", [Validators.required])
      );
    }
    if (!this.isNewUserPage) {
      ourPersonalDataForm.addControl(
        "birthdate",
        new FormControl("", [Validators.required])
      );

      ourPersonalDataForm.addControl(
        "maritalStatus",
        new FormControl("", [Validators.required])
      );

      ourIdentityDataForm.addControl(
        "nidDate",
        new FormControl("", [Validators.required])
      );

      ourIdentityDataForm.addControl(
        "nid_image",
        new FormControl("", [Validators.required])
      );
    }

    // If there is a user, we show user data
    const userId = this._activatedRoute.snapshot.paramMap.get("userId");

    this.isAddNewUser = !Boolean(userId);

    if (userId) {
      this.userId = Number(userId);

      this.userData = (await this.getUserData(this.userId))[0];
      this.userDataOptional = this.userData;
      this.nationality = ((this.userData.nid.startsWith("41")? "غير ":"" ) + "سعودي") || '';

      if (this.userData.phone.startsWith("+966")) {
        this.userData.phone = this.userData.phone.slice(4);
      }
      this.isViewing = true;
      this._assignUserData(this.userData);
      this.getUserStats(this.userId);
    }
  }

  async getUserData(id: number) {
    this.isLoading = true;
    let userData: [UserData];
    try {
      // HACK: to get data for a single user
      this._tableService.tableUrl = `admin/usersByRole/${this.userRole}`;

      userData = await this._tableService
        .getData<[UserData]>({
          where: { and: [{ id }] },
        })
        .toPromise();
    } catch (error) {
      console.error(error);
    }

    this.isLoading = false;
    return userData;
  }

  async getUserStats(id: any) {
    this._http
      .get(`${environment.apiHost}admin/user-cases-inquries/${id}`, {
        headers: getHeadersWithToken(),
      })
      .subscribe((data: any) => {
        this.usercasesCount = data.userCasesCount;
        this.userInquiriesCount = data.userInquriesCount;
        this.userCases = data.userCases;
        this.userInquries = data.userInquries;
      });
  }

  private _assignUserData(userData: UserData) {
    // Loop over controls
    Object.keys(this.saveUserForm.controls).forEach((controlName) => {
      for (const filed in userData) {
        // Loop over all non object fields
        if (typeof userData[filed] !== "object") {
          (this.saveUserForm.controls[controlName] as FormGroup).controls[
            filed
          ]?.setValue(userData[filed]);
        } else {
          // Loop over nested object fields
          for (const nestedFiled in userData[filed]) {
            (this.saveUserForm.controls[controlName] as FormGroup).controls[
              nestedFiled
            ]?.setValue(userData[filed][nestedFiled]);
          }
        }
      }
    });
  }

  get isEditPersonalData(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.PERSONAL_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  get isEditIdentityData(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.IDENTITY_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  get isEditProfessionalData(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.PROFESSIONAL_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  get isEditLawyerData(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.LAWYER_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  get isEditContactData(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.CONTACT_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  get isEditOurCustomerDATA(): UserInputMode {
    return this.isAddNewUser ||
      this.editableSection === SavedUserData.OUR_CUSTOMER_DATA
      ? UserInputMode.EDIT
      : UserInputMode.VIEW;
  }

  async onSubmit(section: SavedUserData) {
    const userFormValue = this.saveUserForm.value;

    // Add new user
    if (section === SavedUserData.ALL) {
      const newUserData = { additionalData: {} } as UserData;
      for (const filed in userFormValue) {
        const nestedValue = userFormValue[filed];
        for (const nestedFiled in nestedValue) {
          if (
            ["username", "phone", "email", "nid", "isDisabled"].includes(
              nestedFiled
            )
          ) {
            newUserData[nestedFiled] = nestedValue[nestedFiled];
          } else
            newUserData.additionalData[nestedFiled] = nestedValue[nestedFiled];
        }
      }

      // Add usename based on firstName and lastName
      newUserData.username = `${newUserData.additionalData.firstName} ${newUserData.additionalData.lastName}`;
      newUserData.roleId = RolesId[this.userRole];
      if (this.userRole == UserRoles.user) {
        newUserData['isFinishedAdditionalStep'] = false;
      }

      // HACK: to skip validation
      newUserData.isDisabled = Boolean(newUserData.isDisabled) as unknown as number;

      try {
        this.isAddingNewUser = true;
        newUserData.phone = "+966" + newUserData.phone;
        newUserData.verified = true;
        await this._handleUsersService.createNewUser(newUserData);

        this.goBack();
      } catch (error) {
        console.error(error);

        if (error.error.error) {
          const { message } = error.error.error;
          this._checkDuplicatedError(
            message,
            "Email",
            SavedUserData.CONTACT_DATA,
            "email"
          );

          this._checkDuplicatedError(
            message,
            "Phone",
            SavedUserData.CONTACT_DATA,
            "phone"
          );

          this._checkDuplicatedError(
            message,
            "National ID",
            SavedUserData.IDENTITY_DATA,
            "nid"
          );
        }
      }

      this.isAddingNewUser = false;
    }
    // Update the corresponding section
    else {
      const updatedUserData = { additionalData: {} } as UserData;
      for (const filed in userFormValue[section]) {
        if (["username", "phone", "email", "nid", "isDisabled"].includes(filed)) {
          updatedUserData[filed] = userFormValue[section][filed];
        } else
          updatedUserData.additionalData[filed] = userFormValue[section][filed];
      }

      try {
        this.isEditingUser = true;

        const clonedUserData = JSON.parse(
          JSON.stringify({
            roleId: RolesId[this.userRole],
            ...updatedUserData,
            additionalData: {
              ...this.userData.additionalData,
              ...updatedUserData.additionalData,
            },
          })
        );

        for (const data in updatedUserData.additionalData) {
          if (updatedUserData.additionalData[data] instanceof File) {
            delete clonedUserData.additionalData[data];

            clonedUserData.additionalData[data] = new File(
              [updatedUserData.additionalData[data]],
              updatedUserData.additionalData[data].name,
              { type: updatedUserData.additionalData[data].type }
            );
          }
        }

        // Update usename based on firstName and lastName
        clonedUserData.username = `${clonedUserData.additionalData.firstName} ${clonedUserData.additionalData.lastName}`;

        // For all users
        delete clonedUserData.userCasesCount;
        // For lawyers
        delete clonedUserData.totalWorkedMinutes;

        const phone = clonedUserData.phone;
        if (phone && !phone.startsWith("+966"))
          clonedUserData.phone = "+966" + phone;

        clonedUserData.verified = clonedUserData.isDisabled ? 0 : 1;
        await this._handleUsersService.updateUser(this.userId, clonedUserData);

        this.userData = { ...this.userData, ...clonedUserData };

        this.editableSection = null;
      } catch (error) {
        error.error?.error?.details?.forEach((error) => {
          if (error.path === ".email") {
            this._checkDuplicatedError(
              error.path,
              error.path,
              SavedUserData.CONTACT_DATA,
              "email"
            );
          }

          if (error.path === ".phone") {
            this._checkDuplicatedError(
              error.path,
              error.path,
              SavedUserData.CONTACT_DATA,
              "phone"
            );
          }

          if (error.path === ".nid") {
            this._checkDuplicatedError(
              error.path,
              error.path,
              SavedUserData.IDENTITY_DATA,
              "nid"
            );
          }
        });
      }

      this.isEditingUser = false;
    }
  }

  private _checkDuplicatedError(
    errorMessage: string,
    message: string,
    parentControl: SavedUserData,
    control: string
  ) {
    if (errorMessage.startsWith(message)) {
      addDuplicatedError(
        (this.saveUserForm.controls[parentControl] as FormGroup).controls[
        control
        ]
      );
    }
  }

  goBack() {
    this._router.navigate([`${this.userRole}-management`]);
  }

  copyApplicationNo(applicationNo: string) {
    this.currentCopiedText = applicationNo;
    navigator.clipboard.writeText(applicationNo);
  }
}
