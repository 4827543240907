import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/core/auth.service";
import { getDate } from "src/app/shared/helpers/convertDate";
import { FilterUsersDialogComponent } from "src/app/users/dialogs/filter-users-dialog/filter-users-dialog.component";
import * as moment from "moment-timezone";

import {
  TableService,
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

@Component({
  selector: "app-services-statistics-reports",
  templateUrl: "./services-statistics-reports.component.html",
  styleUrls: ["./services-statistics-reports.component.scss"],
})
export class ServicesStatisticsReportsComponent
  extends DataTableComponentBase
  implements OnInit, OnDestroy
{
  moduleName = "services-report";
  url: string;
  isSearching: boolean = false;
  serviceSlug: string = "khdmat_alastsharh_alqanwnyh";
  searchKeyword: string = "";

  servicesTabs = [
    {
      title: "خدمات الاستشارة القانونية",
      slug: "khdmat_alastsharh_alqanwnyh",
      count: 0,
    },
    {
      title: "خدمات الترافع أمام المحكمة",
      slug: "khdmat_altrafa_amam_almhkmh",
      count: 0,
    },
    {
      title: "الخدمات القانونية الكتابية و الإجرائية",
      slug: "alkhdmat_alqanwnyh_alktabyh_walajraeyh",
      count: 0,
    },
  ];

  displayedColumns: TableSchema[] = [
    {
      title: "consumer-name",
      id: "consumerName",
      type: TableTypes.text,
      editable: false,
    },
    // {
    //   title: "inquiry-num",
    //   id: "applicationNo",
    //   type: TableTypes.text,
    //   editable: true,
    // },
    // {
    //   title: "inquiry-supervisor",
    //   id: "supervisorName",
    //   type: TableTypes.text,
    //   editable: false,
    // },
    // {
    //   title: "inquiry-lawyer-name",
    //   id: "lawyerName",
    //   type: TableTypes.text,
    //   editable: false,
    // },
    {
      title: "case-date",
      id: "created_at",
      type: TableTypes.text,
      editable: false,
    },
    {
      title: "nationality",
      id: "nationality",
      type: TableTypes.text,
      editable: true,
      notOnFilter: true,
    },
    {
      title: "Area",
      id: "region",
      type: TableTypes.text,
      editable: true,
      notOnFilter: true,
    },
    // {
    //   title: "service-status",
    //   id: "status",
    //   type: TableTypes.select,
    //   list: [
    //     { title: "declined", value: "declined", fontColor: "#0F6E5F" },
    //     { title: "closed", value: "closed", fontColor: "#AB1212" },
    //     { title: "finished", value: "finished", fontColor: "#3F3A3A" },
    //     { title: "cancelled", value: "cancelled" },
    //     { title: "new", value: "new" },
    //     { title: "pending", value: "pending", fontColor: "#E5B46D" },
    //     { title: "modify", value: "modify" },
    //     { title: "active", value: "active", fontColor: "#1246AB" },
    //   ],
    //   editable: false,
    //   notOnFilter: false,
    // },
    {
      title: "payStatus",
      id: "subscriptionType",
      type: TableTypes.select,
      list: [
        { title: "مدفوعة", value: "Subscription", fontColor: "#03865a" },
        { title: "طلب إعفاء", value: "ExemptionRequest", fontColor: "#caa29a" },
      ],
    },
    {
      title: "referral-source",
      id: "referralSource",
      type: TableTypes.text
    }
  ];
  dataSource;
  isDataLoading = false;

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;
  dialogSub$!: Subscription;
  filterUsers!: any;
  filterUsersObj: any = {};

  filters: any = {
    limit: 10,
    offset: 0,
    order: ["id DESC"],
    where: {
      and: [],
    },
  };

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.url = "admin/services/report";

    const caseColumns = [
      {
        title: "case-num",
        id: "applicationNo",
        type: TableTypes.text,
        editable: true,
      },
      {
        title: "service_name",
        id: "caseTitle",
        type: TableTypes.text,
        editable: true,
      },
      {
        title: "responsible-for-the-case",
        id: "supervisorName",
        type: TableTypes.text,
        editable: false,
      },
      {
        title: "lawyer-name",
        id: "lawyerName",
        type: TableTypes.text,
        editable: false,
      },
    ];

    const inquiryColumns = [
      {
        title: "inquiry-num",
        id: "applicationNo",
        type: TableTypes.text,
        editable: true,
      },
      {
        title: "service_name",
        id: "caseTitle",
        type: TableTypes.text,
        editable: true,
      },
      {
        title: "inquiry-supervisor",
        id: "supervisorName",
        type: TableTypes.text,
        editable: false,
      },
      {
        title: "inquiry-lawyer-name",
        id: "lawyerName",
        type: TableTypes.text,
        editable: false,
      },
    ];

    this._activatedRoute.queryParams.subscribe((params) => {
      this.serviceSlug = params["slug"];
      if (
        this.serviceSlug === "khdmat_alastsharh_alqanwnyh" ||
        !this.serviceSlug
      ) {
        this.serviceSlug = "khdmat_alastsharh_alqanwnyh";
        this.displayedColumns.splice(1, 0, ...inquiryColumns);
        this.displayedColumns.splice(8, 0, {
          title: "service-status",
          id: "status",
          type: TableTypes.select,
          list: [
            { title: "inquiry-new", value: "pending", fontColor: "#E5B46D" },
            { title: "active", value: "active", fontColor: "#1246AB" },
            { title: "inquiry-closed", value: "closed", fontColor: "#565252" },
            { title: "rejected", value: "rejected", fontColor: "#AB1212" },
            { title: "declined", value: "declined", fontColor: "#0F6E5F" },
            { title: "cancelled", value: "cancelled" },
          ],
          editable: false,
          notOnFilter: false,
        });
      } else {
        this.displayedColumns.splice(1, 0, ...caseColumns);
        this.displayedColumns.splice(8, 0, {
          title: "service-status",
          id: "status",
          type: TableTypes.select,
          list: [
            { title: "declined", value: "declined", fontColor: "#0F6E5F" },
            { title: "closed", value: "closed", fontColor: "#AB1212" },
            { title: "finished", value: "finished", fontColor: "#3F3A3A" },
            { title: "cancelled", value: "cancelled" },
            { title: "new", value: "new" },
            { title: "pending", value: "pending", fontColor: "#E5B46D" },
            { title: "modify", value: "modify" },
            { title: "active", value: "active", fontColor: "#1246AB" },
          ],
          editable: false,
          notOnFilter: false,
        });
      }
    });
  }

  async ngOnInit() {
    await this.fetchServicesCounts();
  }

  async fetchServicesCounts(): Promise<void> {
    this._tableService.tableUrl = `admin/services/report`;
    try {
      const requests = this.servicesTabs.map(async (service) => {
        const response: any = await this._tableService
          .getDataCount({ and: [{ slug: service.slug }] })
          .toPromise();
        service.count = response.count;
      });
      await Promise.all(requests);
    } catch (error) {
      console.error("Error fetching service counts:", error);
    }
  }

  async getData(filters?: Filter) {
    this.isDataLoading = true;
    this._tableService.tableUrl = `admin/services/report`;
    if (
      !filters.where.and ||
      filters.where.and.length === 0 ||
      filters.where.and.every((obj) => Object.keys(obj).length === 0)
    ) {
      filters.where.and = [{ slug: this.serviceSlug }];
    } else {
      // Remove any existing object containing a 'slug' property
      filters.where.and = filters.where.and.filter(
        (obj) => !obj.hasOwnProperty("slug")
      );
      // Add the new slug object
      filters.where.and.unshift({ slug: this.serviceSlug });
    }
    try {
      this.dataSource = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((reportData) => ({
        ...reportData,
        consumerName: reportData.userOwner.username ?? "-",
        supervisorName: reportData.userSupervisor.username ?? "-",
        lawyerName: reportData.userAssignee.username ?? "-",
        created_at: moment(new Date(reportData.created_at)).format("LL"),
        nationality: !reportData.userOwner?.nid.startsWith("41")
          ? "سعودي"
          : "غير سعودي",
        referralSource: reportData.referralSource ?? "-",
      }));

      if (
        Object.keys(this._dataTableComponent.backendFilter.where.and[0])
          .length !== 0 ||
        this._dataTableComponent.backendFilter.where.and.length >= 1
      ) {
        this._dataTableComponent.tableDataLength = (
          await this._tableService
            .getDataCount(this._dataTableComponent.backendFilter.where)
            .toPromise()
        ).count;
      }
    } catch (error) {
      console.error(error);
    }
    this.isDataLoading = false;
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = `admin/services/report`;
      let filters = { ...this._dataTableComponent.backendFilter };
      if (filters["limit"]) delete filters["limit"];
      if (filters["offset"]) delete filters["offset"];
      let exportData = (
        await this._tableService.getData<any>({ where: filters.where }).toPromise()
      ).map((reportData) => ({
        ...reportData,
        consumerName: reportData.userOwner.username ?? "-",
        supervisorName: reportData.userSupervisor.username ?? "-",
        lawyerName: reportData.userAssignee.username ?? "-",
        nationality: !reportData.userOwner.nid.startsWith("41")
        ? "سعودي"
        : "غير سعودي",
        subscriptionType: reportData.subscriptionType == 'Subscription' ? "مدفوعة" : "طلب إعفاء"
      }));
      await this._dataTableComponent.downloadAllData(exportData);
    } catch (error) {
      console.error(error);
    }
  }

  async getDataForAction(data) {
    this._tableService.tableUrl = `/services/report`;

    this._tableService.SaveItem({ ...data }).subscribe(
      (_) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
  }

  async handleSearchChange(value: string) {
    this.isDataLoading = true;
    if (!value) this._dataTableComponent.paginator.firstPage();
    this.searchKeyword = value;
    this.filters.where.and = this.filters.where.and.filter(
      (item) => !item.keyword
    );
    if (value.length > 0) {
      this.filters.where.and.push({ keyword: value });
    } else {
      if (this.filters.where.and.length === 0) {
        this._router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this._router.navigate(["services-statistics-reports"], {
              queryParams: { slug: this.serviceSlug },
            });
          });
      }
    }

    this._dataTableComponent.backendFilter = this.filters;

    this.getData(this._dataTableComponent.backendFilter);

    this._dataTableComponent.tableDataLength = (
      await this._tableService
        .getDataCount(this._dataTableComponent.backendFilter.where)
        .toPromise()
    ).count;

    this.isDataLoading = false;
  }

  showUserData({ data }) {
    if (!data) {
      this.handleCustomFilter("customFilter");
    }
  }

  // handle filter dialog
  async handleCustomFilter(data: any) {
    if (data == "customFilter") {
      this.dialogSub$ = this._dialog
        .open(FilterUsersDialogComponent, {
          direction: "rtl",
          panelClass: "save-case-dialog",
          data: {
            list: "services-statistics",
            subscriptionStatus: [
              {
                title: "الكل",
                value: "all",
                fontColor: "#080808",
              },
              {
                title: "مدفوعة",
                value: "Subscription",
                fontColor: "#03865a",
              },
              {
                title: "طلب إعفاء",
                value: "ExemptionRequest",
                fontColor: "#caa29a",
              },
            ],
            serviceStatus:
              this.serviceSlug === "khdmat_alastsharh_alqanwnyh"
                ? [
                    { title: "all", value: "all" },
                    { title: "inquiry-new", value: "pending" },
                    { title: "active", value: "active" },
                    { title: "inquiry-closed", value: "closed" },
                    { title: "rejected", value: "rejected" },
                  ]
                : [
                    { title: "all", value: "all" },
                    { title: "declined", value: "declined" },
                    { title: "closed", value: "closed" },
                    { title: "finished", value: "finished" },
                    { title: "cancelled", value: "cancelled" },
                    { title: "new", value: "new" },
                    { title: "pending", value: "pending" },
                    { title: "modify", value: "modify" },
                    { title: "active", value: "active" },
                  ],
          },
        })
        .afterClosed()
        .subscribe(async (filterUsers: any) => {
          if (filterUsers) {
            this.isDataLoading = true;
            this.handleFilter(filterUsers);
          }
        });
    }
  }

  // handle filteration
  async handleFilter(obj: any) {
    this.filterUsers = obj;
    let body: any = { and: [] };
    if (obj.dateToValue && obj.dateFromValue) {
      body.and = [
        { created_at: { lt: obj.dateToValue } },
        { created_at: { gt: obj.dateFromValue } },
      ];
    }

    if (
      obj.subscriptionStatus &&
      obj.subscriptionStatus !== undefined &&
      obj.subscriptionStatus !== "all"
    ) {
      body.and.push({ subscriptionType: obj.subscriptionStatus });
    }
    if (
      obj.serviceStatus &&
      obj.serviceStatus !== undefined &&
      obj.serviceStatus !== "all"
    ) {
      body.and.push({ status: obj.serviceStatus });
    }

    if (this.searchKeyword.length > 0) {
      body.and.push({ keyword: this.searchKeyword });
    }

    for (let item of body.and) {
      if (item.created_at) {
        if (item.created_at.lt) {
          this.filterUsersObj.dateToValue = item.created_at.lt;
        }
        if (item.created_at.gt) {
          this.filterUsersObj.dateFromValue = item.created_at.gt;
        }
      }
      if (item.subscriptionType !== undefined) {
        this.filterUsersObj.subscriptionType = item.subscriptionType;
      }
      if (item.status !== undefined) {
        this.filterUsersObj.status = item.status;
      }
    }

    this.filters["where"] = {
      ...this.filters["where"],
      ...body,
    };

    this._dataTableComponent.backendFilter = this.filters;
    this.getData(this.filters);
    this._dataTableComponent.tableDataLength = (
      await this._tableService.getDataCount(this.filters["where"]).toPromise()
    ).count;
    this.isDataLoading = false;
  }

  //remove filter - type and update filter and display it from api
  removeFilter(value: any) {
    if (value == "dateFromValue" || value == "dateToValue") {
      delete this.filterUsersObj["dateFromValue"];
      delete this.filterUsersObj["dateToValue"];
      delete this.filterUsers["dateFromValue"];
      delete this.filterUsers["dateToValue"];
    } else if (value == "subscriptionType") {
      delete this.filterUsersObj[value];
      delete this.filterUsers["subscriptionStatus"];
    } else if (value == "status") {
      delete this.filterUsersObj[value];
      delete this.filterUsers["serviceStatus"];
    } else {
      delete this.filterUsersObj[value];
      delete this.filterUsers[value];
    }

    if (Object.keys(this.filterUsersObj).length === 0) {
      this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this._router.navigate(["services-statistics-reports"], {
          queryParams: { slug: this.serviceSlug },
        });
      });
    } else {
      this.handleFilter(this.filterUsers);
    }
  }

  async changeActiveTab(slug: string) {
    this.serviceSlug = slug;
    this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this._router.navigate(["services-statistics-reports"], {
        queryParams: { slug: this.serviceSlug },
      });
    });
  }

  ngOnDestroy(): void {
    this.dialogSub$?.unsubscribe();
  }
}
