import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MAT_SELECT_CONFIG, MatSelectChange } from "@angular/material/select";
import { TableSchema } from "@kortobaa-front/k-ng-datatable";
export interface dateSelect {
  startDate: String;
  endData: String;
}
export const MY_FORMATS = {
  parse: {
    dateInput: "LL",
  },
  display: {
    dateInput: "LL",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
@Component({
  selector: "app-filter-users-dialog",
  templateUrl: "./filter-users-dialog.component.html",
  styleUrls: ["./filter-users-dialog.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "filterSelectCustom" },
    },
    { provide: MAT_DATE_LOCALE, useValue: "ar" },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class FilterUsersDialogComponent implements OnInit {
  isLoading = false;
  today = new Date();
  month = new Date().getMonth();
  year = new Date().getFullYear();
  selectDate: dateSelect = {
    startDate: "",
    endData: "",
  };
  choosedField: TableSchema = null;

  filterDataForm = this._fb.group({
    field: [""],
    operator: [""],
    value: [],
    dateFromValue: ["", this.data.list !== 'usercases-status'? Validators.required : ""],
    dateToValue: ["", this.data.list !== 'usercases-status'? Validators.required : ""],
    isDisabled: ["", this.data.list === "users-list" ? Validators.required : ""],
    saudi: ["", this.data.list === "users-list" ? Validators.required : ""],
    idle: [""],
    caseType: [""],
    caseStatus: [""],
    inquiryStatus: [""],
    subscriptionStatus: [""],
    serviceStatus: [""],
    serviceName: [""],
  });

  constructor(
    public dialogRef: MatDialogRef<FilterUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder
  ) {}

  ngOnInit(): void {}

  // filter form submit
  filter(filterForm: FormGroup) {
    let filterObj: any = { ...filterForm.value };
    filterObj.dateFromValue = filterForm.value.dateFromValue
      ? new Date(filterForm.value.dateFromValue).toJSON()
      : undefined;
    filterObj.dateToValue = filterForm.value.dateFromValue
      ? new Date(filterForm.value.dateToValue).toJSON()
      : undefined;

    if (filterObj.dateToValue && filterObj.dateFromValue) {
      filterObj.dateToValue = new Date(
        new Date(filterObj.dateToValue).setHours(23, 59, 59, 999)
      ).toISOString();
      filterObj.dateFromValue = new Date(
        new Date(filterObj.dateFromValue).setHours(0, 0, 1, 0)
      ).toISOString();
    } else if (filterObj.filterBy) {
      filterObj.dateToValue = this.selectDate.endData;
      filterObj.dateFromValue = this.selectDate.startDate;
    }

    this.dialogRef.close(filterObj);
  }

  getCurrentWeek() {
    this.selectDate.startDate = new Date(
      new Date(
        new Date(this.today.getTime() - 6 * 24 * 60 * 60 * 1000)
      ).setHours(0, 0, 1, 0)
    ).toJSON();
    this.selectDate.endData = new Date().toJSON();
  }

  getCurrentMonth() {
    this.selectDate.startDate = new Date(this.year, this.month, 1).toJSON();
    this.selectDate.endData = new Date().toJSON();
  }

  closeDialog(data?: boolean) {
    this.dialogRef.close(data);
  }

  changeFiled(e: MatSelectChange) {
    this.choosedField = this.data.displayData.find(
      (field) => field.id == e.value
    );

    const valueInput = this.filterDataForm.controls["value"];

    // Add validators to value input
    if (this.choosedField.type == "number") {
      valueInput.setValidators([
        Validators.required,
        Validators.pattern(/^[-+]?[0-9]+$/),
      ]);
    } else {
      valueInput.setValidators([Validators.required]);
    }

    valueInput.updateValueAndValidity();

    if (!this.choosedField?.type) {
      this.filterDataForm.controls["operator"].disable();
    } else {
      this.filterDataForm.controls["operator"].enable();
    }
  }

  ngOnDestroy() {}
}
