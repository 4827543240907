import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";

import { Subscription } from "rxjs";

import {
  TableService,
  TableSchema,
  TableTypes,
  DataTableComponentBase,
  DataTableComponent,
  Filter,
} from "@kortobaa-front/k-ng-datatable";

import { AuthService } from "src/app/core/auth.service";
import { getDate } from "src/app/shared/helpers/convertDate";
import * as moment from "moment-timezone";
import { FilterUsersDialogComponent } from "src/app/users/dialogs/filter-users-dialog/filter-users-dialog.component";
import { ComplaintDetailsDialogComponent } from "../../dialogs/complaint-details-dialog/complaint-details-dialog.component";

@Component({
  selector: "app-complaints-page",
  templateUrl: "./complaints-page.component.html",
  styleUrls: ["./complaints-page.component.scss"],
})
export class ComplaintsPageComponent
  extends DataTableComponentBase
  implements OnInit, OnDestroy
{
  moduleName = "complaints";
  url: string;
  isSearching: boolean = false;

  displayedColumns: TableSchema[] = [
    {
      title: "consumer-name",
      id: "consumerName",
      type: TableTypes.text,
    },
    {
      title: "request-num",
      id: "application_id",
      type: TableTypes.text,
    },
    {
      title: "service_name",
      id: "serviceName",
      type: TableTypes.text,
    },
    {
      title: "complaint-date",
      id: "complaintDate",
      type: TableTypes.text,
      editable: true,
      notOnFilter: true,
    },
    {
      title: "complaint",
      id: "complaint",
      type: TableTypes.extrnalinfo,
      notOnFilter: true,
    },
  ];
  dataSource;
  isDataLoading = false;

  @ViewChild(DataTableComponent)
  private _dataTableComponent: DataTableComponent;
  dialogSub$!: Subscription;
  filterUsers!: any;
  filterUsersObj: any = {};

  filters: any = {
    limit: 10,
    offset: 0,
    order: ["created_at DESC"],
    where: {
      and: [],
    },
  };
  searchKeyword: string = "";

  constructor(
    public authService: AuthService,
    private _tableService: TableService,
    private _dialog: MatDialog,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {
    super();
    this.url = "admin/complains";
  }

  async ngOnInit() {}

  async getData(filters?: Filter) {
    this.isDataLoading = true;
    this._tableService.tableUrl = `admin/complains`;
    filters.order = this.filters.order;
    try {
      this.dataSource = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((complaint) => ({
        ...complaint,
        application_id:
          complaint.application_type == "case"
            ? complaint.application_id
            : complaint.inquiryId,
        consumerName: complaint.userOwner.username,
        serviceName:
          complaint.service?.title ??
          (complaint.application_type === "inquiry"
            ? "خدمات الاستشارة القانونية"
            : "-"),
        complaintDate: moment(new Date(complaint.created_at)).format("LL"),
        complaint: complaint.description
      }));

      if (
        Object.keys(this._dataTableComponent.backendFilter.where.and[0])
          .length !== 0 ||
        this._dataTableComponent.backendFilter.where.and.length >= 1
      ) {
        this._dataTableComponent.tableDataLength = (
          await this._tableService
            .getDataCount(this._dataTableComponent.backendFilter.where)
            .toPromise()
        ).count;
      }
    } catch (error) {
      console.error(error);
    }
    this.isDataLoading = false;
  }

  async getDataForAction(data) {
    delete data["birthDate"];

    this._tableService.tableUrl = `/complains`;

    this._tableService.SaveItem({ ...data }).subscribe(
      (_) => {
        this._tableService.redirect(this.url);
      },
      (error) => {
        alert(error.error.error.message);
      }
    );
  }

  async handleSearchChange(value: string) {
    this.isDataLoading = true;
    if (!value) this._dataTableComponent.paginator.firstPage();
    this.searchKeyword = value;
    this.filters.where.and = this.filters.where.and.filter(
      (item) => !item.keyword
    );
    if (value.length > 0) {
      this.filters.where.and.push({ keyword: value });
    } else {
      if (this.filters.where.and.length === 0) {
        this._router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this._router.navigate(["complaints"]);
          });
      }
    }

    this._dataTableComponent.backendFilter = this.filters;

    this.getData(this._dataTableComponent.backendFilter);

    this._dataTableComponent.tableDataLength = (
      await this._tableService
        .getDataCount(this._dataTableComponent.backendFilter.where)
        .toPromise()
    ).count;

    this.isDataLoading = false;
  }

  showUserData({ data }) {
    if (data) {
      this._dialog.open(ComplaintDetailsDialogComponent, {
        direction: "rtl",
        panelClass: "save-case-dialog",
        data: data,
      });
    } else {
      this.handleCustomFilter("customFilter");
    }
  }

  // handle filter dialog
  async handleCustomFilter(data: any) {
    if (data == "customFilter") {
      this.dialogSub$ = this._dialog
        .open(FilterUsersDialogComponent, {
          direction: "rtl",
          panelClass: "save-case-dialog",
          data: {
            displayData: this.displayedColumns,
            list: "user-complaint",
          },
        })
        .afterClosed()
        .subscribe(async (filterUsers: any) => {
          if (filterUsers) {
            this.isDataLoading = true;
            this.handleFilter(filterUsers);
          }
        });
    }
  }

  // handle filteration
  async handleFilter(obj: any) {
    this.filterUsers = obj;
    let body: any = { and: [] };
    if (obj.dateToValue && obj.dateFromValue) {
      body.and = [
        { created_at: { lt: obj.dateToValue } },
        { created_at: { gt: obj.dateFromValue } },
      ];
    }

    if (this.searchKeyword.length > 0) {
      body.and.push({ keyword: this.searchKeyword });
    }

    for (let item of body.and) {
      if (item.created_at) {
        if (item.created_at.lt) {
          this.filterUsersObj.dateToValue = item.created_at.lt;
        }
        if (item.created_at.gt) {
          this.filterUsersObj.dateFromValue = item.created_at.gt;
        }
      }
    }

    this.filters["where"] = {
      ...this.filters["where"],
      ...body,
    };

    this._dataTableComponent.backendFilter = this.filters;
    this.getData(this.filters);
    this._dataTableComponent.tableDataLength = (
      await this._tableService.getDataCount(this.filters["where"]).toPromise()
    ).count;
    this.isDataLoading = false;
  }

  //remove filter - type and update filter and display it from api
  removeFilter(value: any) {
    if (value == "dateFromValue" || value == "dateToValue") {
      delete this.filterUsersObj["dateFromValue"];
      delete this.filterUsersObj["dateToValue"];
      delete this.filterUsers["dateFromValue"];
      delete this.filterUsers["dateToValue"];
    } else {
      delete this.filterUsersObj[value];
      delete this.filterUsers[value];
    }
    if (Object.keys(this.filterUsersObj).length === 0) {
      this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
        this._router.navigate(["complaints"]);
      });
    } else {
      this.handleFilter(this.filterUsers);
    }
  }

  async getExportData(data) {
    try {
      this._tableService.tableUrl = `admin/complains`;
      let filters = { ...this._dataTableComponent.backendFilter };
      if (filters["limit"]) delete filters["limit"];
      if (filters["offset"]) delete filters["offset"];
      let exportData = (
        await this._tableService.getData<any>(filters).toPromise()
      ).map((complaint) => ({
        ...complaint,
        application_id:
          complaint.application_type == "case"
            ? complaint.application_id
            : complaint.inquiryId,
        consumerName: complaint.userOwner.username,
        serviceName:
          complaint.service?.title ??
          (complaint.application_type === "inquiry"
            ? "خدمات الاستشارة القانونية"
            : "-"),
        complaintDate: moment(new Date(complaint.created_at)).format("LL"),
        complaint: complaint.description
      }));
      await this._dataTableComponent.downloadAllData(exportData);
    } catch (error) {
      console.error(error);
    }
  }

  ngOnDestroy(): void {
    this.dialogSub$?.unsubscribe();
  }
}
